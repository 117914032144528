import { Component, OnInit } from '@angular/core';

/** permite el uso del comodín $ de jquery en cualquier componente de la aplicación */
declare const $: any;

/**
 * Componente inicial de la aplicación, aquí se carga el router-outlet
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /**
   * Callback init del componente, usado para desactivar el comportamiento default de los elementos html &lt;a&gt;&lt;/a&gt;
   * cuando se da clic sobre uno de estos.
   */
  ngOnInit(): void {
    $(document).on('click', '[href="#"]', (e: MouseEvent) =>
      e.preventDefault()
    );
  }
}
