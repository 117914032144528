<header>
    <nav class="navbar">
        <div class="container grid grid-nogutter">
            <div class="col-6">
                <a href="#/capacidadesti/public/home">
                    <img loading="lazy" width="110" height="45" src="./assets/images/sura-logo.png"
                        alt="Logo Sura" />
                </a>
            </div>
            <div class="col-6">
                <div class="container__button">
                    <a class="container__login-button" href="#/capacidadesti/private/home">Iniciar sesión</a>
                    <a class="container__login-icon" href="#/capacidadesti/private/home">
                        <em class="bi bi-box-arrow-in-right"></em>
                    </a>
                </div>
            </div>
        </div>
    </nav>
</header>