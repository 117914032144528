import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { deleteAllCookies } from 'cookies-utils';

/**
 * Interceptor HTTP cuya responsabilidad es controlar la autorización y autenticacion global de peticiones hacia el backend
 */
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptRequestService implements HttpInterceptor {
  /**
   * Constructor de la clase
   *
   * @param router objeto para controlar el enrutamiento de la aplicación
   */
  constructor(private router: Router) {}

  /**
   * intercepta las peticiones
   *
   * @param req petición
   * @param next handler para ejecutar la logica cuando se captura la petición
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let reqWithHeaders 
    const token = req.headers.get('recaptcha-token')
    if(token != null){
     reqWithHeaders = req.clone({
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-APP-RELAYSTATE': location.href,
           'recaptcha-token':token
        }),  withCredentials: true
      });
    }else{
      reqWithHeaders = req.clone({
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-APP-RELAYSTATE': location.href,
        }),  withCredentials: true
      });
    }

    return next.handle(reqWithHeaders).pipe(
      catchError((err) => {
        if (err.status && err.status === 401) {
          document.write(err.error);
        }
        if (
          (err.status && err.status === 500) ||
          (err.status && err.status === 0)
        ) {
          window.location.href = '#/capacidadesti/private/home';
        }
        return throwError(err);
      })
    );
  }
}
