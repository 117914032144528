import { NgModule } from '@angular/core';
import { PublicLayoutComponent } from './public-layout.component';
import { PublicHeaderComponent } from './public-header/public-header.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [PublicLayoutComponent, PublicHeaderComponent],
  imports: [SharedModule]
})
export class PublicLayoutModule { }
