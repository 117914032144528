import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { PublicLayoutModule } from './public-layout/public-layout.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptRequestService,InterceptorSecurityService } from '@core/http-interceptors';
import { IdentityService } from '@core/security';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    PublicLayoutModule,
    LayoutModule,
    RoutesModule,
    CommonModule,
    SharedModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptRequestService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorSecurityService,
      multi: true
    },
    IdentityService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
