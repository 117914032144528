import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SidebarService } from '../../core/sidebar/sidebar.service';
import { MenuHeader, MenuItem } from '../../core/sidebar';
import { Router } from '@angular/router';
import { Usuario } from '@shared/models/usuario';
import { UsuarioService } from '@shared/services/usuario/usuario.service';
import { LogSuccessFactor } from '@shared/models/log_success_factor';
import { SuccessFactorService } from '@shared/services/success-factor/success-factor.service';
import { TriggerNotificacionService } from '@shared/services/trigger-notificacion.service';
import { Subscription } from 'rxjs';


/**
 * Componente para renderizar el panel lateral derecho de la aplicación
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit { 
  isExpanded!:boolean;
  enterUserFirst!:boolean;
  clickPlay!:boolean;
  clickSubMenuOrClick!:boolean;
  temporizador: any = null; 
  perfil = 'Administrador';
  usuarios: Array<Usuario> = new Array<Usuario>(); 
  esCompleto: boolean = false;   
  numero: string = '0';
  esperaEnSegundosCerrado:number =2100;
  isLoadedFecha!: Boolean;
  log!: LogSuccessFactor;

  /** menú del usuario */
  @Input()
  userMenu?: MenuHeader = undefined;
  fechaUltimaRevision!: string;
  numeroVacantes = '0';
  private triggerSubscription: Subscription = new Subscription();
  /**
   * Constructor de la clase
   *
   * @param securityService Servicio de seguridad
   */
  constructor(protected usuarioService: UsuarioService, private sidebarService: SidebarService, private router: Router, private successFactor: SuccessFactorService,private triggerService: TriggerNotificacionService, private renderer: Renderer2, private el: ElementRef) {}

  /**
   * Callback init del componente, aquí se carga el menú del usuario.
   */
  ngOnInit(): void {
    this.isLoadedFecha = false;
    this.isExpanded  = false; 
    this.enterUserFirst  = false
    this.clickPlay = false
    this.clickSubMenuOrClick=false
    const self = this;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    self.toggleSubmenu(this.userMenu!.menuItems[0]);
    self.setMenuAsActive(self.router.url);
    this.infoIncompleta();
    this.consultarLiderLogueado();
    this.successFactor.getLog().subscribe(data => {
      if (data) {
        this.log = data;
        this.isLoadedFecha = true;
      }
    });
  }
  /** badge de alerta usuario info incompleta */
  consultarLiderLogueado() { 
    this.usuarioService.consultarLiderLogueado().subscribe((usuarios) => {     
      this.fechaUltimaRevision = usuarios.fechaUltimaRevision;  
    });        
  }
  
  /** badge de alerta usuario info incompleta */
  infoIncompleta() {   
    this.usuarioService.getUsuariosIncompletos().subscribe((usuarios) => {     
      this.numero = usuarios.length.toString();

      if(this.numero != '0'){
      this.esCompleto = false;      
      }
      
      else{
        this.esCompleto = true;
      }     
    });        
  }
  
  /**
   * Al darle click en el play activa funcionalidad de expander o contraer
   */
  playSidebar(){
    this.clickSubMenuOrClick = true;
    this.isExpanded= !this.isExpanded;
    this.expandir_contraer_aside();

    this.contraerSubMenu();

    this.changeVisualSubMenu();

    this.agregarExpandenPlay();

    const textBars = document.getElementsByClassName("text-bar")
    for (let i = 0; i < textBars.length; i++) {
      if (this.isExpanded) {
          textBars[i].classList.add('isExpanded');
      } else {
          textBars[i].classList.remove('isExpanded');
      }
    }
    
  }

  resetSidebar(){
    const playSlide = document.getElementById("play");
    const textBars = document.getElementsByClassName("text-bar")
    this.clickSubMenuOrClick= false;
    this.isExpanded = false
    this.clickPlay =false
 

    this.changeVisualSubMenu();
    this.contraerSubMenu();
    this.expandir_contraer_aside();

    playSlide?.classList.remove("isExpanded")

    for(let i = 0; i<textBars.length;i++){
      textBars[i].classList.remove('isExpanded');
    }
    
  }

  agregarExpandenPlay(){
    const playSlide = document.getElementById("play");
    
    if (this.isExpanded) {
      this.clickPlay = true
      playSlide?.classList.add('isExpanded');
    } else {
      this.clickPlay = false
      playSlide?.classList.remove('isExpanded');
    }
  }

  contraerSubMenu(){
    const lists = this.el.nativeElement.querySelectorAll('.list');
    lists.forEach((list:Element) => {
      const paddingValue = this.isExpanded ? '0.6vw' : '0';
      this.renderer.setStyle(list, 'padding-left', paddingValue);
    });
  }

  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }

  expandir_contraer_aside(){
    this.sidebarService.setSidebarState(this.isExpanded)
  }

  cerradoAutomatico(){
    if(this.isExpanded && this.clickPlay){
        this.resetSidebar()
        this.changeVisualSubMenu()
      
    
    }
  }

  changeVisualSubMenu(){
    const lists = this.el.nativeElement.querySelectorAll('.float-sidemenu');
    lists.forEach((list:Element) => {
      const paddingValue = this.isExpanded ? ' inline' : 'none';
      this.renderer.setStyle(list, 'display', paddingValue);
    });
  }

  mouseEnter() {
    if (this.temporizador) {
      clearTimeout(this.temporizador);
    }
  }

  mouseLeave() {
    console.log("salio")
    this.temporizador = setTimeout(() => {
      this.cerradoAutomatico()
    }, this.esperaEnSegundosCerrado);
  }

  /**
   * indica si se debe mostrar el submenu, esto ejecuta la animación
   */
  @Input()
  menuName = '';

  /**
   * Cambia el estado activo/inactivo del menuitem pasado como parametro
   *
   * @param menu objeto menuitem seleccionado
   */
  toggleSubmenu(menu: MenuItem): void {
    // desactivamos todos los menus
    this.sidebarService.inactivateAllItems(this.userMenu?.menuItems || []);

    const isActive = menu.active;

    menu.active = !isActive;

    this.clickSubMenuOrClick=false;


    if(!this.clickPlay){
      if(!this.enterUserFirst){
        this.expandir_contraer_aside();
        this.enterUserFirst = !this.enterUserFirst;
      }else{
        this.playSidebar()
        this.clickSubMenuOrClick = false
      }
    }    
    
  }

  /**
   * Marca los items que deben ser activados del menu cuando se carga la pagina
   *
   * @param url url del router
   */
  setMenuAsActive(url: string): void {
    if (!this.userMenu) {
      return;
    }

    this.userMenu.menuItems.forEach((item) =>
      this.markIfIsActive(item, '/', url)
    );
  }

  /**
   * marca de forma recursiva los items activos cuando se refresca la pagina, para que en el menú salgan seleccionados
   *
   * @param item Item a comprobar
   * @param baseUrl url base
   * @param url complemento de la url
   */
  markIfIsActive(item: MenuItem, baseUrl: string, url: string): void {
    const shouldBeMarked =
      baseUrl.concat(item.contextPath) === url ||
      url.indexOf(baseUrl.concat(item.contextPath)) === 0;

    if (item.children && item.children.length > 0) {
      // const childBaseUrl = baseUrl.concat(item.contextPath).concat('/');
      item.children.forEach((childItem) =>
        this.markIfIsActive(childItem, '/', url)
      );
    }

    item.active = shouldBeMarked;
  }

}
