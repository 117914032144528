<!-- top navbar-->
<div style="display: flex;
flex-direction: column;
height: 100vh;">
      <app-header *ngIf="user" [user]="user"></app-header>
      <div class="container" [ngClass]="{'header-menu-active': menuActive}">    
            <!-- sidebar -->
            <app-sidebar id="aside-responsive" class="aside-bar aside" *ngIf="user" [userMenu]="user!.menu" [class.isExpanded]="isExpanded" ></app-sidebar>          
            <!-- Page content-->      
            <div class="app-content-wrapper" (click)="this.sidebarService.setStatus(false)">        
                  <router-outlet></router-outlet>
                  <!-- Page footer-->
                  <app-footer></app-footer>
            </div>
            <!-- Page Mask-->
            <div class="layout-mask"></div>
      </div> 
</div>