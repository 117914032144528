<nav class="navbar">
  <a href="#" class="sidebar-icon" (click)="changeSidebarStatus()" [ngClass]="{ 'header-menu-active' : menuActive }">
    <em class="bi bi-three-dots-vertical"></em>
  </a>
  <div class="p-fluid grid" style=" margin-left: 35px; align-items: center;">
    <div class="col-1 col-md-4 input" style="width: 220px;">
      <a>
        <img loading="lazy" width="160" height="70" src="./../../../assets/images/Sura_logotipo.jpg" alt="SuraLogo" />
      </a>
    </div>
    <div class="col-1 col-md-4 input" style="width: 20px;">
      <a>
        <img loading="lazy" width="3" height="60" src="./../../../assets/images/line_vertical.png"
          alt="line_vertical" />
      </a>
    </div>
    <div class="col-6 col-md-4 input" style="width: 300px;">
      <a>
        <span> App de Gestión de Capacidades </span>
        <span style="font-weight: bold;"> 4.0 </span>
      </a>
    </div>
  </div>
  <a href="#" class="profile-menu" (click)="userMenuActive = !userMenuActive">
    <div class="profile-menu__name">
      <span>{{ user?.nombreCompleto }}</span>
    </div>
    <img class="profile-menu__image" alt="user-icon" src="assets/images/user-logo.png">
    <em class="pi pi-angle-down"></em>
  </a>

  <ul class="float-menu" [ngClass]="{ 'profile-menu_active': userMenuActive }">
    <li class="float-menu__name">
      <span>Último ingreso: {{ user?.fechaUltimaConexion }}</span>
    </li>
    <li class="float-menu__account">
      <a href="https://micuenta.suranet.com/" rel="noopener" target="_blank">
        <em class="pi pi-user mr-2"></em>
        <span>Mi cuenta</span>
      </a>
    </li>
    <li class="float-menu__logout">
      <a href="#" (click)="logout()">
        <em class="pi pi-power-off mr-2"></em>
        <span>Cerrar Sesion</span>
      </a>
    </li>
  </ul>
</nav>