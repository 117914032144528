import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ScrollPanelModule } from 'primeng/scrollpanel';

import { Error403Component } from './global-error-components';
import { MaskDirective } from './directives/mask.directive';

/**
 *  Modulo donde se puede realizar instanciación modular de clases
 */
@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule],
  declarations: [Error403Component, MaskDirective],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ScrollPanelModule,
    Error403Component,
    MaskDirective
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
