import { Component } from "@angular/core";
import { IdentityService } from "@core/security/identity.service";

/**
 *
 * Componente, usado para renderizar una pagina de error http 403 que indica cuando un usuario no
 * tiene acceso a un recurso específico.
 */
@Component({
  selector: "app-http-error-403",
  templateUrl: "./error403.component.html",
  styleUrls: ["./error403.component.scss"],
})
export class Error403Component {
constructor(private identityService: IdentityService) {}

  logout(): void {
    this.identityService.fullLogout();
  }
}
