import { Component, Input, OnInit } from '@angular/core';
import { Usuario } from '@shared/models/usuario';
import { TriggerNotificacionService } from '@shared/services/trigger-notificacion.service';
import { UsuarioService } from '@shared/services/usuario/usuario.service';
import { Subscription } from 'rxjs';
import { SidebarService, MenuItem } from 'src/app/core/sidebar';

/**
 * Componente de presentación encargado de rederizar submenus
 */
@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent implements OnInit {
  /**
   * items que deben ser renderizados
   */
  @Input()
  itemsMenu?: MenuItem = undefined;

  /**
   * indica si se debe mostrar el submenu, esto ejecuta la animación
   */
  @Input()
  menuName = '';

  /**
   * path del menu padre, este se usa para completar el enrutamiento
   */
  @Input() basePath = '';

  /** menú del usuario */
  @Input()
  completeMenu?: Array<MenuItem> = undefined;

  /**
   * Constructor
   *
   * @param sidebarService servicio del sidebar
   */
  numero = '0';
  private triggerSubscription: Subscription = new Subscription();
  constructor(
    private sidebarService: SidebarService,
    protected usuarioService: UsuarioService,
    private triggerService: TriggerNotificacionService
  ) {}

  ngOnInit(): void {
    if(this.basePath == "capacidadesti/private/tabla-principal"){
      this.buscarNotificacionVacantes();
    }
  }

  /**
   * Cambia el estado activo/inactivo del menuitem pasado como parametro
   *
   * @param menu objeto menuitem seleccionado
   */
  toggleSubmenu(menu: MenuItem): void {
    // desactivamos todos los menus
    this.sidebarService.inactivateAllItems(this.completeMenu || []);

    const isActive = menu.active;

    if (!this.itemsMenu) {
      return;
    }

    this.itemsMenu.active = !isActive;
    menu.active = !isActive;
  }

  /** badge de alerta de notificaciones */
  buscarNotificacionVacantes() {
    this.usuarioService.getNotificaciones().subscribe((vacantes: Usuario[]) => {
      this.numero = vacantes.length.toString();
    });
  }
}
