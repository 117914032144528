<ul class="float-sidemenu">    
    <em class="bi bi-caret-left-fill icon"></em>
    <li *ngFor="let menu of itemsMenu?.children" class="float-sidemenu__item">
        <div (click)="toggleSubmenu(menu)" [routerLink]="menu.children && menu.children.length > 0 ? null : '/'.concat(menu.contextPath)" [ngClass]="{'subitem__active': menu.active}">
            <em *ngIf="menu.name !== 'Notificación de Vacantes'||numero==='0'" [ngClass]="menu.active === true ? menu.image + '-fill' : menu.image"></em>
            <em pBadge [value]="numero" [ngClass]="menu.active === true ? menu.image + '-fill' : menu.image"
                *ngIf="menu.name === 'Notificación de Vacantes'&&numero!=='0'"></em>
            <a href="#" >
                <span>{{ menu.name }}</span>
            </a>
        </div>
    </li>
</ul>