import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorSecurityService {

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var patronHTML = /<[^>]*>/;
    var patronJS = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
    var patronSQL = /(?:\b(SELECT|INSERT|UPDATE|DELETE|FROM|WHERE|AND|OR|ORDER BY|GROUP BY)\b)/gi;
    const body = req.body;
    if (body && typeof body === 'object') {
      for (const key in body) {
        if (typeof body[key] === 'string') {
          if (patronHTML.test(body[key]) || patronJS.test(body[key]) || patronSQL.test(body[key])) {
            const errorResponse = new HttpErrorResponse({
              error: {
                "nombreExcepcion": "ExcepcionValorInvalido",
                "mensaje": "Contiene Valores Invalidos"
              },
              status: 400,
              statusText: 'Bad Request'
            });
            return throwError(errorResponse);
          } 
        }
      }
    }
      return next.handle(req);
  }
}
