import { Component, OnInit, OnDestroy } from '@angular/core';
import { SidebarService } from '../core/sidebar/sidebar.service';
import { Subscription } from 'rxjs';
import { IdentityService, IdentityUser } from '@core/security';
import { MenuHeader } from '@core/sidebar';

/**
 * Componente padre para la renderización del Layout de la aplicación
 */
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  /**
   * Entidad del usuario en el sistema
   */
  user?: IdentityUser = undefined;

  /**
   * Entidad del usuario en el sistema
   */
  menu?: MenuHeader = undefined;

  /**
   * Indica si el menú esta visible o no
   */
  public menuActive = false;

  /**
   * Subscription para obtener el estado del sidebar
   */
  private sidebarSuscription?: Subscription = undefined;

  public isExpanded = true; 
  private subscription: Subscription;

  /**
   * Constructor
   *
   * @param sidebarService Servicio sidebar
   */
  constructor(
    private identityService: IdentityService,
    public sidebarService: SidebarService,
  ) {
    this.subscription = this.sidebarService.isExpandedObservable.subscribe(
      (expanded) => {
        this.isExpanded = expanded;
        this.playSidebar();
      }
    );
  }

  /**
   * Inicialización, se carga el estado del sidebar para definir la clase que activa la animación cuando se abre o cierra el menú
   */
  ngOnInit(): void {
    this.sidebarSuscription = this.sidebarService.onStatusChanged(
      (status: boolean) => (this.menuActive = status)
    );
    this.identityService.getUserInfo().subscribe((userInfo) => {
      this.user = userInfo;
      this.menu = userInfo.menu;
    });
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LdBPQkqAAAAAOhEYTgFIK_WHez7ynFwYabr7-QM';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

  /**
   * Se libera recursos
   */
  ngOnDestroy(): void {
    if (this.sidebarSuscription) {
      this.sidebarSuscription.unsubscribe();
    }
  }

  playSidebar(){
    this.isExpanded= !this.isExpanded;
    const aside = document.getElementById("aside-responsive");
   
    if (this.isExpanded) {
      aside?.classList.add('isExpanded');
    } else {
      aside?.classList.remove('isExpanded');
    }
    
  }

  
 
}
