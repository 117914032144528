import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit{
  constructor() { }
  ngOnInit(): void {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LdBPQkqAAAAAOhEYTgFIK_WHez7ynFwYabr7-QM';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }
  
}
