import { Injectable } from '@angular/core';

declare const grecaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  execute(action: string) {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha.execute('6LdBPQkqAAAAAOhEYTgFIK_WHez7ynFwYabr7-QM', { action }).then((token: string) => {
          resolve(token);
        }).catch((error: any) => {
          reject(error);
        });
      });
    });
  }
}