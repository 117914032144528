import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { Routes } from '@angular/router';

import { LayoutComponent } from '../layout/layout.component';
import { Error403Component } from '../shared/global-error-components';
import { PublicLayoutComponent } from '../public-layout/public-layout.component';

/**
 * Modulo que incluye los componentes específicos y propios de la aplicación
 */
export const APP_ROUTES: Routes = [
  {
    path: 'capacidadesti',
    component: PublicLayoutComponent,
    children: [
      { path: '', redirectTo: 'public', pathMatch: 'full' },
      {
        path: 'public',
        loadChildren: () =>
          import('./capacidades-public/capacidades-public.module').then(
            (m) => m.CapacidadesPublicModule
          )
      }
    ]
  },
  {
    path: 'capacidadesti',
    component: LayoutComponent,
    children: [
      {
        path: 'private',
        loadChildren: () =>
          import('./capacidades-private/capacidades-private.module').then(
            (m) => m.CapacidadesPrivateModule
          )
      }
    ]
  },

  // errors
  { path: 'access-denied', component: Error403Component },

  // Not found
  { path: '**', redirectTo: 'capacidadesti' }
];
@NgModule({
  imports: [SharedModule, RouterModule.forRoot(APP_ROUTES, { useHash: true })],
  declarations: []
})
export class RoutesModule {}

