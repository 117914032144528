/**
 * Constantes de la aplicación
 */
export class Constants {
  /** url para obtener información del usuario */
  static securityUserInfo = 'seguridad/usuario';

  /** url para concer si un usuario tiene permiso hacia un recurso */
  static securityUserAccess = 'seguridad/acceso';

  /** url para obtener comprobar que un usuario tenga activa una sesión y en caso contrario
   *  obtener el html del formulario de login
   */
  static securityCheckStatus = 'seguridad/estado';
}
