<aside class="sidebar" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" [ngClass]="{'is-expanded': isExpanded}">
  <div class="p-fluid grid" style="margin-left: 0.6vh" >
    <div class="col-12 col-md-4 input" style="width: 70px;">
      <div id="play" (click)="playSidebar()">
        <span class="bi bi-play-fill" ></span>
      </div>
    </div>
  </div>
  <div class="p-fluid grid" style="margin-left: 0.3vh">
    <div class="col-12 col-md-4 input" style="width: 70px;">
      <a>
        <img class="image-line" loading="lazy"  src="./../../../assets/images/line_horizontal.png"
          alt="line_horizontal" />
      </a>
    </div>
  </div>
  <ul class="list" >
    <li class="list__item" *ngFor="let menu of userMenu?.menuItems" [ngClass]="{'list__item_active': menu.active }">
      <hr color="#1D428A" width="90%" style="margin-left: 28px; "/>
      <a class="list__link" href="#"
        [routerLink]="menu.children &&  menu.children.length > 0 ? null : '/'.concat(menu.contextPath)"
        (click)="toggleSubmenu(menu)" >
        <em [ngClass]="menu.active === true ? menu.image + '-fill' : menu.image"
          *ngIf="(menu.image !== 'bi bi-people')&&(menu.name !== 'Información Principal'||numeroVacantes==='0')" [pTooltip]="!isExpanded? menu.name:'' "></em>
        <em pBadge [value]="numero" [ngClass]="menu.active === true ? menu.image + '-fill' : menu.image"
          *ngIf="menu.image == 'bi bi-people'&& !esCompleto"></em>
        <em [ngClass]="menu.active === true ? menu.image + '-fill' : menu.image"
          *ngIf="menu.image == 'bi bi-people' && esCompleto"></em>
        <em pBadge [value]="numeroVacantes" [ngClass]="menu.active === true ? menu.image + '-fill' : menu.image"
                *ngIf="menu.name === 'Información Principal'&&numeroVacantes!=='0'"></em>        
        <div class="text-bar" [class.isExpanded]="isExpanded" >{{ menu.name }}</div>
      </a>
      <!-- <span class="list__link">{{ menu.name }}</span>      -->
      <app-submenu class="submenu" *ngIf="menu.children && menu.children.length > 0" [basePath]="menu.contextPath"
        [itemsMenu]="menu" [menuName]="menu.name" [completeMenu]="userMenu?.menuItems" width="90%" (click)="resetSidebar()">
      </app-submenu>
    </li>
  </ul>
  <div id="p-botton-img" class="p-fluid grid" >
    <div class="col-12 col-md-4 input" style="width: 50px;">
      <a>
        <img class="image-line" loading="lazy"   src="./../../../assets/images/line_horizontal.png"
          alt="line_horizontal" />
      </a>
    </div>
  </div>
  <div class="box-bottom text-bar">
    <div class="p-fluid grid" style="margin-left: 0.3vh; align-items: center;">
      <div class="col-2 col-md-4 input" style="width: 31px;">
        <a>
          <span style="color: #ffffff; font-size: x-large;" class="bi bi-info-circle mr-2"></span>
        </a>
      </div>
      <div class="col-10 col-md-4 input">
        <a>
          <span style="font-weight: bold; font-size: xx-large; color: #ffffff;"><div class="text-bar title-details"> Detalles</div> </span>
        </a>
      </div>    
      <div class="col-12 col-md-4 input"
        style="background: #1D428A;  width: 90%; color: #ffffff; border-radius: 10px;">
        <div *ngIf="isLoadedFecha">Último consumo en Success Factor: <strong>{{ log.fechaCarga | date:'medium' }}</strong>
        </div>
      </div>
      <div class="col-12 col-md-4 input"
      style="background: #1D428A;  width: 90%; color: #ffffff; border-radius: 10px;margin-top: 10px;">
      <div *ngIf="isLoadedFecha">Última revisión de su equipo:<br><strong>{{ fechaUltimaRevision }}</strong>
      </div>
    </div>
    </div>  
  </div>
</aside>