import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogSuccessFactor } from '@shared/models/log_success_factor';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuccessFactorService {

  constructor(protected http: HttpClient) { }

  public getLog(): Observable<LogSuccessFactor> {
    return this.http
      .get(`${environment.localBack}successfactor/log`)
      .pipe(map((response) => response as LogSuccessFactor));
  }
}
