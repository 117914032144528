import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { IdentityService } from './identity.service';
import { environment } from 'src/environments/environment';
import { RecaptchaService } from '@shared/services/recaptcha/recaptcha.service';

/**
 * Clase Guard cuyo fin es la de ejercer la autenticación y la autorizacion sobre las urls del sistema de enrutamiento de
 * angular.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate, CanActivateChild {
  /**
   * Constructor de la clase
   *
   * @param router objeto para controlar el enrutamiento de la aplicación
   * @param seusService servicio que se conectará con el backend para validar seguridad
   */
  constructor(private router: Router, private seusService: IdentityService,private recaptchaService: RecaptchaService) {}

  /**
   * funcion implementada por la interface @link{CanActivate}, se ejecuta para el componente donde se configure
   *
   * @param route contiene información sobre una ruta de un componente cargado en un outlet durante un momento de tiempo particular
   * @param state representa el estado del router en un momento de tiempo
   * @returns Promise booleano donde si es True se permite el acceso a la url.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.doActivate(state);
  }

  /**
   * funcion implementada por la interface @link{canActivateChild}, se ejecuta por cada ruta hija en el modulo o componente
   * donde se configure
   *
   * @param route contiene información sobre una ruta de un componente cargado en un outlet durante un momento de tiempo particular
   * @param state representa el estado del router en un momento de tiempo
   * @returns Promise booleano donde si es True se permite el acceso a la url.
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.doActivate(state);
  }

  /**
   * realiza la comprobación de autenticación e invoca la autorización de ser necesario
   *
   * @param state representa el estado del router en un momento de tiempo
   * @returns Promise booleano donde si es True si esta autenticado o si se permite el acceso a la url.
   */
  doActivate(state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolver) => {
      if (!this.seusService.isLoggedIn()) {
        this.recaptchaService.execute('formulario').then((token:any) => {
          this.seusService.getLoginForm(token).subscribe(
            (form) => {
              if (form.mensaje === 'LoggedInYet') {
                this.checkAuthorization(state.url.substring(1), resolver);
              } else {
                document.write(form.mensaje);
              }
            },
            (error) => {
              console.error(error);
              if (
                (error.status && error.status === 500) ||
                (error.status && error.status === 0)
              ) {
                window.location.href = environment.logout;
              }
            }
          );
        }).catch(error => { });

      }

      this.checkAuthorization(state.url.substring(1), resolver);
    });
  }

  /**
   * Esta funcion valida si un usuario tiene autorizacion a una url y en caso que no la tenga reenvia el flujo a una pantalla
   * de acceso denegado.
   *
   * @param url es la ruta del componente
   * @param resolver permite que se pueda completar el promise.
   */
  checkAuthorization(url: string, resolver: (param: boolean) => void): void {
      this.seusService.isAuthorized(url).subscribe(
        (res) => {
          if (!res) {
            this.router.navigate(['access-denied']);
          }
          resolver(res);
        },
        (error) => {
          if (
            (error.status && error.status === 500) ||
            (error.status && error.status === 0)
          ) {
            window.location.href = environment.logout;
          } else {
            this.router.navigate(['access-denied']);
            resolver(false);
          }
        }
      );

  }
}
