import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TriggerNotificacionService {
  public trigger$: Observable<boolean>;
  private triggerSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.trigger$ = this.triggerSubject.asObservable();
  }

  triggerUpdate() {
    this.triggerSubject.next(true);
  }
}
