<nav class="navbar">
    <div class="p-fluid grid" style=" margin-left: 35px; align-items: center;">
      <div class="col-1 col-md-4 input" style="width: 220px;">
        <a>
          <img loading="lazy" width="160" height="70" src="./../../../assets/images/Sura_logotipo.jpg" alt="SuraLogo" />
        </a>
      </div>
      <div class="col-1 col-md-4 input" style="width: 20px;">
        <a>
          <img loading="lazy" width="3" height="60" src="./../../../assets/images/line_vertical.png"
            alt="line_vertical" />
        </a>
      </div>
      <div class="col-6 col-md-4 input" style="width: 300px;">
        <a>
          <span> App de Gestión de Capacidades </span>
          <span style="font-weight: bold;"> 4.0 </span>
        </a>
      </div>
    </div>
    <a href="#" class="profile-menu" (click)="logout()">
      <div class="profile-menu__name">
        <span>Cerrar Sesion</span>
      </div>
      <img class="profile-menu__image" alt="user-icon" src="assets/images/user-logo.png">
    </a>
  </nav>
<div class="wrapper">
  <div class="abs-center wd-xl">
      <!-- START panel-->
      <div class="text-center mb-xl">
          <div class="text-lg mb-lg">403</div>
          <p class="lead m0">Acceso denegado :(</p>
          <p>Lo sentimos! No tienes permisos para ver el recurso al que intentas acceder.</p>
      </div>
      <ul class="list-inline text-center text-sm mb-xl">
          <li><a class="text-muted" [routerLink]="'/'">Volver al Home</a>
          </li>
      </ul>
  </div>
</div>